import React from 'react'
import { css } from '@emotion/react'
import Container, { ContainerProps } from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import useTheme from '@mui/material/styles/useTheme'

export type TextAlignmentValues = 'left' | 'center' | 'right'

export interface TextAlignmentConfiguration {
  xs?: TextAlignmentValues
  sm?: TextAlignmentValues
  md?: TextAlignmentValues
  lg?: TextAlignmentValues
  xl?: TextAlignmentValues
}

function computeTextAlign(
  curr: keyof TextAlignmentConfiguration,
  alignment?: TextAlignmentConfiguration,
) {
  const sizes = ['xs', 'sm', 'md', 'lg', 'xl']
  let size: keyof TextAlignmentConfiguration
  while (sizes.length) {
    size = sizes.pop() as typeof size
    if (curr === size) {
      return alignment?.[size]
    }
  }
}

export interface PageProps {
  ContainerProps?: Partial<ContainerProps>
  alignment?: {
    title?: TextAlignmentConfiguration
    subtitle?: TextAlignmentConfiguration
  }
  id?: string
  variant?: 'fluid' | 'post'
  path?: string
  title?: string
  subtitle?: React.ReactNode
  style?: React.CSSProperties
  renderSubtitle?: () => React.ReactNode
}

function Page(props: React.PropsWithChildren<PageProps>) {
  const theme = useTheme()
  const {
    alignment,
    id,
    style,
    children,
    title,
    subtitle,
    renderSubtitle,
    ContainerProps,
    variant,
  } = props

  return (
    <Container
      id={id}
      css={css`
        max-width: ${variant == 'fluid'
          ? '100%'
          : variant == 'post'
          ? '700px'
          : '800px'} !important;
        ${theme.breakpoints.down('xs')} {
          font-size: 20px;
        }
      `}
      {...ContainerProps}
      style={{ ...style, ...ContainerProps?.style }}
    >
      <div style={{ height: 50 }} />
      {title && (
        <Typography
          css={css`
            ${theme.breakpoints.down('xl')} {
              text-align: ${computeTextAlign('xl', alignment?.title)};
            }
            ${theme.breakpoints.down('lg')} {
              text-align: ${computeTextAlign('lg', alignment?.title)};
            }
            ${theme.breakpoints.down('md')} {
              text-align: ${computeTextAlign('md', alignment?.title)};
            }
            ${theme.breakpoints.down('sm')} {
              text-align: ${computeTextAlign('sm', alignment?.title)};
            }
            ${theme.breakpoints.down('xs')} {
              text-align: ${computeTextAlign('xs', alignment?.title)};
            }
          `}
          variant="h4"
          gutterBottom
        >
          {title}
        </Typography>
      )}
      {renderSubtitle?.() ||
        (subtitle && (
          <Typography
            css={css`
              ${theme.breakpoints.down('xl')} {
                text-align: ${computeTextAlign('xl', alignment?.subtitle)};
              }
              ${theme.breakpoints.down('lg')} {
                text-align: ${computeTextAlign('lg', alignment?.subtitle)};
              }
              ${theme.breakpoints.down('md')} {
                text-align: ${computeTextAlign('md', alignment?.subtitle)};
              }
              ${theme.breakpoints.down('sm')} {
                text-align: ${computeTextAlign('sm', alignment?.subtitle)};
              }
              ${theme.breakpoints.down('xs')} {
                text-align: ${computeTextAlign('xs', alignment?.subtitle)};
              }
            `}
            style={{ color: 'rgba(0, 0, 0, 0.55)' }}
            gutterBottom
          >
            {subtitle}
          </Typography>
        ))}
      {(title || renderSubtitle || subtitle) && children && (
        <div
          css={css`
            height: 30px;
            ${theme.breakpoints.down('xs')} {
              font-size: 20px;
            }
          `}
        />
      )}
      {children}
    </Container>
  )
}

export default Page
